.funnel-thumbnail,
.add-funnel-thumbnail,
.import-funnel-thumbnail {
    width: 300px;
    height: 300px;
    margin: 8px;
    float: left;
    border: 5px solid #e7eaec;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    position: relative;
    background: #fff;
  }
  .add-funnel-thumbnail {
    height: 325px;
    margin-top:0px;
  }
  .choose-template {
    height: 320px;
  }

  .funnel-thumbnail:hover,
  .add-funnel-thumbnail:hover{
    border: 3px solid #1ab394;
    -webkit-box-shadow: 0 3px 7px 0 #a8a8a8;
    -moz-box-shadow: 0 3px 7px 0 #a8a8a8;
    box-shadow: 0 3px 7px 0 #a8a8a8;
}

.funnel-thumbnail .choose{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0px;
    background: #2f4050;
    opacity: 0.6;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 235px;
    padding-top: 50px;
    visibility: hidden;

   
}

.centerItem{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.addIcon{
    height: 50px;
    width: 50px;
}

.funnel-thumbnail .delete{
    position: absolute;
    right: 0;
    left: 0;
    bottom: -150px;
    background: #f30909;
    opacity: 1;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 50px;
    transition: all 0.5s ease 0s;
}

.funnel-thumbnail :hover .choose {
    cursor: pointer;
    visibility: visible;
    transition: all 0.5s ease 0s;
}
.funnel-thumbnail :hover .delete {
    top: 0;
}

.thumbnail-img {
    height: 230px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
    
.thumbnail-content {
    height: 62px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    bottom:0;    
    padding-left: 10px; 
}

.thumbnailTitle {
    font-weight: bold;
    font-size: 16px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}
     
.thumbnail-content .abbrtitle {
    border-bottom: none ;    
    text-decoration: none ;
    cursor: pointer;
}
.centerList{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.funnel-template .settings-icon-container  {
    width: 21%;
    background: #1ab394;
    height: 65px;
}

.funnel-template .settings-icon-container span{
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 16px;
    padding: 0 20px;
    cursor: pointer;
    align-content: center;
}
.funnel-template .settings-icon-container span i{
    font-size: 28px;
    line-height: 62px;
}
.thumbnail-container {
    height: 62px;
    width: 100%;
    display: flex;
} 
.funnel-template .funnel-thumbnail {
    margin-top: 0px;
    border-top: none;
}
.funnel-template .thumbnail-container {
    border-top: 2px solid;
    border-top-color: #1ab394;
}
.funnel-template
.thumbnail-content {
    width: 80% ;
}

.funnel-template {
    border-top:none;
    margin-top: 0px;
}

.funnel-template .status-indicator{
    width: 300px;
    height: 5px;    
    background-color: red;
    visibility: visible;
    margin: 0 8px;
}
.funnel-template .active-template{
    background-color: #00e676 ;
}

.status-indicator {
    visibility: hidden;
  }

.funnel-template .page-template-type{
    margin: 0 8px;
    width:300px;
}
.funnel-template-type {
    padding: 5px;
    height: 25px;
    background: #3e5066;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    top: 0;
    left: 0;
    z-index: 100;
    position: absolute;
}
.existing-list{
    max-height: 420px;
    padding: 20px;
    border: 1px solid #e7eaec;
    margin-bottom: 20px;
    margin-right: 0px ;
    margin-left: 0px;
}
.funnel-choose{
    display: flex;
    justify-content: center;
    height: 240px;
    width: 100%;
    align-items: center;
    cursor: pointer;
}



