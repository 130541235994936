.thumbnail-card-item {
    .template-content:hover {
        .choose {
            bottom: 0;
        }
    }
    .template-card-price {
        position: absolute;
        bottom: 0;
        background: rgba(white, 0.6);
        width: 100%;
        text-align: left;
        padding: 10px;
        font-size: 16px;
    }
    width: 166px;
    margin-right: 30px;
    display: inline-block;
}
.showTemplatesList {
    .thumbnail-card-item {
        margin-right: 0px;
    }
}
.split-thumbnail {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.split-thumbnail-img {
    height: 250px;
    display: flex;
    float: left;
    width: 200px;
    margin: 15px;
    border: 5px solid #eeeeee;
    transition: all 0.3s ease 0s;
    background-size: contain;
    background-repeat: no-repeat;
}
.split-thumbnail-img:hover {
    border: 3px solid #1ab394;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.4);
    cursor: pointer;
}
.split-unedited {
    border: 3px solid red;
}
.split-edited {
    border: 3px solid green;
}