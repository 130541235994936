.progress-tracker {
    ol {
        list-style-type: none;
        padding: 0;
        border-bottom: 4px solid silver;
        color: silver;
        position: relative;
        line-height: 10px;

        li {
            display: inline-block;
            position: relative;
            width: 20%;
            text-align: center;
            .dot {
                position: relative;
                height: 15px;
                width: 15px;
                background-color: silver;
                border-radius: 50%;
                display: inline-block;
                border:2px solid #bbb;
            }

            &.step-completed, &.step-active {
                color: $primary-color;
                .dot {
                    background-color: $primary-color;
                }
                .progress-label {
                    color: $primary-color;
                }
            }

            .progress-label {
                position: absolute;
                width: 100%;
                bottom: -20px;
                color: #777;
                text-transform: uppercase;
                font-size: 12px;
            }

            .step-sub-item {
                visibility: hidden;
                &.show-sub-item {
                    visibility: visible;
                }
                .dot {
                    width: 12px;
                    height: 12px;
                }
                .progress-label {
                    font-size: 10px;
                    color: $dark-blue-1;
                }
            }
        }
    }
    
}