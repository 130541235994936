
// @include triangle($width $height, $foreground-color $background-color, $direction)
// $height and $background-color can be omitted, if so, $height will equal to $width and $background-color will be transparent.
// $direction contains the following arguments: up, down, left, right, up-right, up-left, down-right, down-left, inset-up, inset-down, inset-left, inset-right.

$ribbon-background: #f7b500;
@mixin triangle ($size, $color, $direction) {
    height: 0;
    width: 0;
  
    $width: nth($size, 1);
    $height: nth($size, length($size));
  
    $foreground-color: nth($color, 1);
    $background-color: transparent !default;
    @if (length($color) == 2) {
      $background-color: nth($color, 2);
    }
  
    @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
  
      $width: $width / 2;
  
      @if $direction == up {
        border-left: $width solid $background-color;
        border-right: $width solid $background-color;
        border-bottom: $height solid $foreground-color;
  
      } @else if $direction == right {
        border-top: $width solid $background-color;
        border-bottom: $width solid $background-color;
        border-left: $height solid $foreground-color;
  
      } @else if $direction == down {
        border-left: $width solid $background-color;
        border-right: $width solid $background-color;
        border-top: $height solid $foreground-color;
  
      } @else if $direction == left {
        border-top: $width solid $background-color;
        border-bottom: $width solid $background-color;
        border-right: $height solid $foreground-color;
      }
    }
  
    @else if ($direction == up-right) or ($direction == up-left) {
      border-top: $height solid $foreground-color;
  
      @if $direction == up-right {
        border-left:  $width solid $background-color;
  
      } @else if $direction == up-left {
        border-right: $width solid $background-color;
      }
    }
  
    @else if ($direction == down-right) or ($direction == down-left) {
      border-bottom: $height solid $foreground-color;
  
      @if $direction == down-right {
        border-left:  $width solid $background-color;
  
      } @else if $direction == down-left {
        border-right: $width solid $background-color;
      }
    }
  
    @else if ($direction == inset-up) {
      border-width: $height $width;
      border-style: solid;
      border-color: $background-color $background-color $foreground-color;
    }
  
    @else if ($direction == inset-down) {
      border-width: $height $width;
      border-style: solid;
      border-color: $foreground-color $background-color $background-color;
    }
  
    @else if ($direction == inset-right) {
      border-width: $width $height;
      border-style: solid;
      border-color: $background-color $background-color $background-color $foreground-color;
    }
  
    @else if ($direction == inset-left) {
      border-width: $width $height;
      border-style: solid;
      border-color: $background-color $foreground-color $background-color $background-color;
    }
  }
  
    
// Ribbon


    [class^="ribbon-"] {
        position: absolute;
        margin-bottom: 80px;
        right: 10px;
        &:before, &:after {
        content: "";
        position: absolute;
        }
    }
  
  
    // Ribbon-1
    .ribbon-price {
        width: 70px;
        height: 80px;
        background: $ribbon-background;
        top: -6px;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;
        font-weight: bold;
        font-size: 18px;

        &:before {
            @include triangle(6px, darken($ribbon-background, 15%), down-left);
            right: -6px;
        }
        &:after {
            @include triangle(70px 35px, transparent $ribbon-background, up);
            bottom: -35px;
        }
    }