.profilePicture{
    border:1px solid #ccc;
    margin-top: 15px;
}
.type-label{
    padding-top: 2px;
    margin-bottom: 0;
}
.edit-modal{
    margin-bottom: 0px;
}
.add-domain {
    text-align: right;
}
.domain-name {
    text-align: left;
}
.edit-domain {
    margin-right: 5px;
}
.domain-table {
    margin-top: 15px;
}
.shield-green {
    color: green;    
}
.shield-red {
    color: red;    
}
.fa-shield {
    margin-right: 5px;
}