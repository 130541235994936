@import "./scss/variables.scss";
@import "./scss/helpers/typography";
@import './scss/helpers/layout';
@import './scss/helpers/animation';
@import './scss/helpers/align';
@import './scss/components/ribbon';
@import "./scss/components/modal";
@import "./scss/components/thumbnail-cards";
@import "./scss/components/progress-tracker";
@import "./scss/components/form";
@import "./scss/components/notifications";
@import "./scss/components/dashboard";
@import "./scss/components/visualizer/visualizer";
@import "./scss/components/share-page";
@import "./scss/components/templates";
@import "./scss/components/choose_templates";

/*
 * Fix for grapesjs overlap in panels
 */

.gjs-pn-views-container {
    width: 15%;
}

// To increase the font size of the editor
.gjs-rte-actionbar {
    font-size: 1.25rem;
}
