.notifications-container {
    position: fixed;
    top: 20px;
    right: 10px;
    z-index: 3000; // Modal dialog z-index is 2040

    .notifications {
        min-width: 300px;
        color: #fff;
        padding: 15px;
        border-radius: 4px;
        transition: all 0.4s ease 0s;
        margin-bottom: 10px;
        position: relative;

        &.notification-success {
            background: rgba(75,181,67,0.8);
        }

        &.notification-error {
            background: red; 
        }

        .notification-close-button {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
}
