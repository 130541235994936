@charset "UTF-8";
.secondary-text {
  color: #9c9c9e; }

.no-gutters {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.flex-vertical-align {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

[class^="ribbon-"] {
  position: absolute;
  margin-bottom: 80px;
  right: 10px; }
  [class^="ribbon-"]:before, [class^="ribbon-"]:after {
    content: "";
    position: absolute; }

.ribbon-price {
  width: 70px;
  height: 80px;
  background: #f7b500;
  top: -6px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  font-weight: bold;
  font-size: 18px; }
  .ribbon-price:before {
    height: 0;
    width: 0;
    border-bottom: 6px solid #ab7d00;
    border-right: 6px solid transparent;
    right: -6px; }
  .ribbon-price:after {
    height: 0;
    width: 0;
    border-left: 35px solid #f7b500;
    border-right: 35px solid #f7b500;
    border-bottom: 35px solid transparent;
    bottom: -35px; }

.modal .modal-header {
  background: #1ab394; }

.modal .modal-content .close-button {
  position: absolute;
  right: 20px;
  top: 10px;
  display: inline-block;
  font-family: FontAwesome;
  text-rendering: auto;
  font-size: 16px;
  font-weight: normal;
  background: #ccc;
  padding: 3px 6px;
  color: #2f4050;
  cursor: pointer; }
  .modal .modal-content .close-button:before {
    content: "\f00d"; }

.modal .modal-content .modal-title {
  font-size: 16px;
  font-weight: 600;
  color: white; }

.modal.modal-top-spacer {
  top: 30px; }

.modal-right-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
  .modal-right-options .share-section {
    width: auto; }

.existing-funnels-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden; }

.existing-funnels-modal .modal-dialog {
  width: 90%;
  height: 90%; }

.existing-funnels-modal .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  box-shadow: none; }

.thumbnail-card-item {
  width: 166px;
  margin-right: 30px;
  display: inline-block; }
  .thumbnail-card-item .template-content:hover .choose {
    bottom: 0; }
  .thumbnail-card-item .template-card-price {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    text-align: left;
    padding: 10px;
    font-size: 16px; }

.showTemplatesList .thumbnail-card-item {
  margin-right: 0px; }

.split-thumbnail {
  display: flex;
  width: 100%;
  justify-content: space-around; }

.split-thumbnail-img {
  height: 250px;
  display: flex;
  float: left;
  width: 200px;
  margin: 15px;
  border: 5px solid #eeeeee;
  transition: all 0.3s ease 0s;
  background-size: contain;
  background-repeat: no-repeat; }

.split-thumbnail-img:hover {
  border: 3px solid #1ab394;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  cursor: pointer; }

.split-unedited {
  border: 3px solid red; }

.split-edited {
  border: 3px solid green; }

.progress-tracker ol {
  list-style-type: none;
  padding: 0;
  border-bottom: 4px solid silver;
  color: silver;
  position: relative;
  line-height: 10px; }
  .progress-tracker ol li {
    display: inline-block;
    position: relative;
    width: 20%;
    text-align: center; }
    .progress-tracker ol li .dot {
      position: relative;
      height: 15px;
      width: 15px;
      background-color: silver;
      border-radius: 50%;
      display: inline-block;
      border: 2px solid #bbb; }
    .progress-tracker ol li.step-completed, .progress-tracker ol li.step-active {
      color: #1ab394; }
      .progress-tracker ol li.step-completed .dot, .progress-tracker ol li.step-active .dot {
        background-color: #1ab394; }
      .progress-tracker ol li.step-completed .progress-label, .progress-tracker ol li.step-active .progress-label {
        color: #1ab394; }
    .progress-tracker ol li .progress-label {
      position: absolute;
      width: 100%;
      bottom: -20px;
      color: #777;
      text-transform: uppercase;
      font-size: 12px; }
    .progress-tracker ol li .step-sub-item {
      visibility: hidden; }
      .progress-tracker ol li .step-sub-item.show-sub-item {
        visibility: visible; }
      .progress-tracker ol li .step-sub-item .dot {
        width: 12px;
        height: 12px; }
      .progress-tracker ol li .step-sub-item .progress-label {
        font-size: 10px;
        color: #1c84c6; }

.form-group.validation-error input {
  border-color: red; }

.notifications-container {
  position: fixed;
  top: 20px;
  right: 10px;
  z-index: 3000; }
  .notifications-container .notifications {
    min-width: 300px;
    color: #fff;
    padding: 15px;
    border-radius: 4px;
    transition: all 0.4s ease 0s;
    margin-bottom: 10px;
    position: relative; }
    .notifications-container .notifications.notification-success {
      background: rgba(75, 181, 67, 0.8); }
    .notifications-container .notifications.notification-error {
      background: red; }
    .notifications-container .notifications .notification-close-button {
      position: absolute;
      top: 5px;
      right: 5px; }

.affiliate-id {
  font-weight: 700;
  font-size: 1.30em; }

.visualizer-header {
  background-color: #2f4050;
  width: 100%;
  padding: 10px 15px;
  text-align: right;
  display: inline-block;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  z-index: 11;
  position: relative;
  /*************** Zoom Section Styling **********************/
  /**********************************************************/
  /*************** Live Site Button Styling **********************/
  /*************** Live Site Button Styling **********************/ }
  .visualizer-header .header-content {
    display: flex;
    justify-content: flex-end;
    flex: 1; }
  .visualizer-header .funnel-title {
    float: left;
    line-height: 34px;
    color: #ffffff;
    margin: 0; }
    .visualizer-header .funnel-title span {
      white-space: nowrap;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left; }
  .visualizer-header .funnelSettings {
    background: #ffffff;
    height: 0;
    z-index: 10;
    transition: all 0.4s ease 0s;
    text-align: left;
    overflow: hidden;
    opacity: 0;
    background: #ffffff;
    border: 1px solid #e7eaec;
    padding: 15px;
    margin-top: -100%;
    margin-bottom: 20px;
    position: absolute;
    top: 40px;
    right: 10px;
    left: 10px;
    z-index: 10;
    transition: all 0.4s ease 2s; }
    .visualizer-header .funnelSettings.active {
      opacity: 1;
      min-height: 355px;
      margin-top: 15px;
      height: auto;
      border-bottom: 5px solid #1ab394;
      padding: 15px;
      transition: all 0.4s ease 0s; }
  .visualizer-header .zoomBox {
    width: 300px; }
    .visualizer-header .zoomBox .input-group-addon {
      background: #1ab394;
      border: none;
      color: #ffffff; }
      .visualizer-header .zoomBox .input-group-addon i {
        font-size: 20px; }
    .visualizer-header .zoomBox .zoom-level {
      text-align: center; }
  .visualizer-header .disable-pointer {
    pointer-events: none; }
  .visualizer-header a.text-white:hover, .visualizer-header a.text-white:focus {
    color: #ffffff; }
  .visualizer-header .dottedLine:hover, .visualizer-header .text-element:hover, .visualizer-header .dottedLine.active, .visualizer-header .text-element.active {
    background: #1ab394; }
  .visualizer-header .dottedLine, .visualizer-header .text-element {
    width: 60px;
    position: relative;
    height: 54px;
    text-align: center;
    line-height: 54px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    overflow: hidden;
    margin-top: -10px;
    margin-bottom: -10px;
    cursor: pointer; }
  .visualizer-header .dottedLine::after {
    content: "";
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgOTcyIDk3MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgOTcyIDk3MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGQ9Ik05MCw1NzZoMC41YzQ5LjcsMCw5MC00MC4zLDkwLTkwcy00MC4zLTkwLTkwLTkwSDkwYy00OS43LDAtOTAsNDAuMy05MCw5MFM0MC4zLDU3Niw5MCw1NzZ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8cGF0aCBkPSJNNjE3LjUsNTc2aDEuMWM0OS43LDAsOTAtNDAuMyw5MC05MHMtNDAuMy05MC05MC05MGgtMS4xYy00OS43LDAtOTAsNDAuMy05MCw5MFM1NjcuOCw1NzYsNjE3LjUsNTc2eiIgZmlsbD0iI0ZGRkZGRiIvPgoJPHBhdGggZD0iTTM1NC41LDU3NmM0OS43LDAsOTAtNDAuMyw5MC05MHMtNDAuMy05MC05MC05MGgtMS4xYy00OS43LDAtOTAsNDAuMy05MCw5MHM0MC4zLDkwLDkwLDkwSDM1NC41eiIgZmlsbD0iI0ZGRkZGRiIvPgoJPHBhdGggZD0iTTg4MS41LDU3NmgwLjVjNDkuNywwLDkwLTQwLjMsOTAtOTBzLTQwLjMtOTAtOTAtOTBoLTAuNWMtNDkuNywwLTkwLDQwLjMtOTAsOTBTODMxLjgsNTc2LDg4MS41LDU3NnoiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 11px;
    margin-left: -15px;
    transform: rotate(-45deg); }

.joint-highlight-stroke.joint-theme-default {
  display: none; }

.joint-cell.active .joint-highlight-stroke.joint-theme-default {
  display: block; }

.marker-vertex-group:first-child .marker-vertex-remove-area, .marker-vertex-group:first-child .marker-vertex-remove, .marker-vertex-group:last-child .marker-vertex-remove-area, .marker-vertex-group:last-child .marker-vertex-remove {
  display: none; }

.connection[stroke-dasharray="2,5"] + .marker-source + .marker-target + .connection-wrap + .marker-vertices + .marker-arrowheads + .link-tools .link-tool .tool-options, .connection[stroke-dasharray="2,5"] + .marker-source + .marker-target + .connection-wrap + .labels + .marker-vertices + .marker-arrowheads + .link-tools .link-tool .tool-options {
  display: none; }

.joint-cell .iconbar, .joint-cell .icons, .joint-cell .linkicon {
  display: none; }

.joint-cell.active + .joint-cell[data-type="standard.Rectangle"] .iconbar, .joint-cell.active + .joint-cell .icons, .joint-cell.active .port-body, .joint-cell.active .iconbar, .joint-cell.active .icons, .joint-cell.active .linkicon {
  display: block; }

.joint-cell.active + .joint-cell[data-type="devs.MyImageModel"] .icons, .joint-cell.active + .joint-cell[data-type="Page Model"] .icons {
  display: none; }

.joint-cell .port-body {
  pointer-events: none; }

.joint-cell.active .port-body {
  pointer-events: fill; }

.joint-cell.port-enable .port-body {
  pointer-events: fill; }

.port-body {
  opacity: 0; }

/*
 * Move this html-element
 */
.visualizer-graph-text-element {
  position: absolute;
  z-index: 2;
  /* Make sure events are propagated to the JointJS element so, e.g. dragging works.*/
  pointer-events: none;
  background: rgba(26, 179, 148, 0.2);
  padding: 10px 20px;
  user-select: none;
  /* Standard syntax */ }
  .visualizer-graph-text-element .icons {
    font-family: FontAwesome;
    font-size: 24px;
    fill: #ffffff;
    cursor: pointer; }
    .visualizer-graph-text-element .icons.trash {
      color: #1ab394; }
  .visualizer-graph-text-element input {
    display: none; }
  .visualizer-graph-text-element .icon-container {
    opacity: 0; }
  .visualizer-graph-text-element.focussed input {
    display: block; }
  .visualizer-graph-text-element.focussed label {
    display: none; }
  .visualizer-graph-text-element.focussed .icon-container {
    opacity: 1;
    pointer-events: auto; }
  .visualizer-graph-text-element .icon-container {
    position: absolute;
    top: -30px;
    left: 0;
    pointer-events: auto;
    z-index: 100; }

.visualizer-graph-input-container {
  position: relative;
  height: 100%; }
  .visualizer-graph-input-container input, .visualizer-graph-input-container label {
    border: none;
    outline: none;
    color: #1ab394;
    font-size: 18px;
    font-weight: bold;
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    user-select: none;
    /* Standard syntax */ }

.visualizer-left-panel {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative; }
  .visualizer-left-panel .pageList {
    position: relative;
    z-index: 1;
    left: 0;
    transition: all 0.4s ease 0s;
    width: 120px;
    display: flex;
    max-height: calc(100vh - 146px);
    overflow: auto; }
    .visualizer-left-panel .pageList .joint-port {
      display: none; }
    .visualizer-left-panel .pageList .label {
      font-weight: 400;
      font-size: 11px;
      fill: #1ab394; }
    .visualizer-left-panel .pageList .pageList .iconbar, .visualizer-left-panel .pageList .pageList text.icons {
      display: none; }

#flyPaper .joint-port {
  display: none; }

.borderBox {
  stroke-width: 1px;
  fill-opacity: 0; }

.visualizer-right-panel {
  position: absolute;
  top: 116px;
  bottom: 0px;
  right: -100%;
  width: 400px;
  box-shadow: -1px 4px 20px #4c4c4c38;
  background: #ffffff;
  transition: all 0.4s ease 0s;
  overflow: auto;
  overflow-x: hidden;
  padding: 20px 0;
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 70px;
  display: inline-block; }
  .visualizer-right-panel.active {
    right: 0; }
  .visualizer-right-panel .ibox-content {
    padding: 0;
    border: 5px solid #e7eaec;
    height: 204px;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    position: relative; }
    .visualizer-right-panel .ibox-content .choose {
      position: absolute;
      right: 0;
      left: 0;
      bottom: -204px;
      background: #2f4050;
      opacity: 0.6;
      z-index: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      height: 204px;
      transition: all 0.5s ease 0s; }
  .visualizer-right-panel .ibox-content:hover .choose {
    bottom: 0; }
  .visualizer-right-panel .custom-checkBox {
    position: fixed !important;
    bottom: 50px;
    box-shadow: 0px -4px 20px #4c4c4c38;
    width: 390px;
    padding: 10px;
    margin: 0 0 0 -15px !important;
    background: #fff;
    z-index: 4; }
  .visualizer-right-panel .custom-checkBox input {
    display: none; }
  .visualizer-right-panel .custom-checkBox label {
    height: 40px;
    background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'><g><g><path d='M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.331,26.629,74.98,74.98C26.628,123.332,0,187.62,0,256 s26.628,132.667,74.98,181.019C123.332,485.371,187.619,512,256,512c68.38,0,132.667-26.629,181.019-74.981 C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M256,482C131.383,482,30,380.617,30,256S131.383,30,256,30 s226,101.383,226,226S380.617,482,256,482z' fill='%23cccccc'/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>");
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 50px !important;
    line-height: 40px;
    font-size: 1.3em; }
  .visualizer-right-panel .custom-checkBox input:checked + label {
    background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'><g><g><path fill='%231ab394' d='M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.332,26.629,74.98,74.98C26.629,123.332,0,187.62,0,256 s26.629,132.667,74.98,181.019C123.332,485.371,187.62,512,256,512s132.667-26.629,181.019-74.98 C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M378.306,195.073L235.241,338.139 c-2.929,2.929-6.768,4.393-10.606,4.393c-3.839,0-7.678-1.464-10.607-4.393l-80.334-80.333c-5.858-5.857-5.858-15.354,0-21.213 c5.857-5.858,15.355-5.858,21.213,0l69.728,69.727l132.458-132.46c5.857-5.858,15.355-5.858,21.213,0 C384.164,179.718,384.164,189.215,378.306,195.073z'/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>") no-repeat; }
  .visualizer-right-panel .checkbox label {
    font-weight: bold !important; }
  .visualizer-right-panel .slider-section {
    float: left;
    width: 100%;
    height: 60px;
    position: relative; }
  .visualizer-right-panel .slider-section .pageValue2 {
    position: absolute;
    top: 14px;
    background: #fff;
    left: 0px;
    padding: 0 10px 0 0;
    right: auto; }

.visualizer-frame {
  display: flex;
  width: calc(100% - 120px);
  padding: 20px;
  overflow: auto; }
  .visualizer-frame .canvas-container {
    position: relative;
    display: flex;
    flex: 1; }
    .visualizer-frame .canvas-container .joint-cell .iconbar, .visualizer-frame .canvas-container .joint-cell .icons, .visualizer-frame .canvas-container .joint-cell .linkicon {
      display: none; }
    .visualizer-frame .canvas-container .joint-cell.active + .joint-cell[data-type="standard.Rectangle"] .iconbar, .visualizer-frame .canvas-container .joint-cell.active + .joint-cell .icons, .visualizer-frame .canvas-container .joint-cell.active .port-body, .visualizer-frame .canvas-container .joint-cell.active .iconbar, .visualizer-frame .canvas-container .joint-cell.active .icons, .visualizer-frame .canvas-container .joint-cell.active .linkicon {
      display: block; }
    .visualizer-frame .canvas-container .joint-cell.active + .joint-cell[data-type="Page Model"] .icons {
      display: none; }
    .visualizer-frame .canvas-container .joint-cell .port-body {
      pointer-events: none; }
    .visualizer-frame .canvas-container .joint-cell.active .port-body {
      pointer-events: fill; }
    .visualizer-frame .canvas-container .joint-cell.port-enable .port-body {
      pointer-events: fill; }
    .visualizer-frame .canvas-container .joint-cell.active .joint-highlight-stroke.joint-theme-default {
      display: block; }
    .visualizer-frame .canvas-container .iconbar {
      fill: #1ab394 !important;
      width: 170px;
      height: 50px; }
    .visualizer-frame .canvas-container text.icons {
      font-family: FontAwesome;
      font-size: 24px;
      fill: #ffffff;
      cursor: pointer; }
    .visualizer-frame .canvas-container text.linkicon {
      font-family: FontAwesome;
      font-size: 20px;
      fill: #1ab394; }
    .visualizer-frame .canvas-container .link-tools .tool-options {
      display: block; }

.visualizer {
  position: relative;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column; }

.visualizer-canvas {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  height: calc(100vh - 155px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative; }

.copy-message {
  position: absolute;
  background: #1ab394;
  color: #ffffff;
  padding: 5px 15px;
  top: 8px;
  z-index: 3;
  border-radius: 5px;
  transition: all 0.4s ease 0s;
  right: -100%;
  opacity: 0; }
  .copy-message:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #1ab394;
    position: absolute;
    bottom: -8px;
    left: 10px;
    transition: all 0.4s ease 0s; }

.share-section {
  text-align: right;
  padding: 15px;
  width: 100%; }

.share-popup {
  float: left;
  width: calc(100% - 30px);
  text-align: left;
  overflow: hidden;
  transition: all 0.4s ease 0s;
  position: absolute;
  z-index: 3;
  background: #ffffff;
  margin-top: 4px;
  padding: 15px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  top: 70px;
  right: 15px; }
  .share-popup.active {
    height: auto;
    transition: all 0.4s ease 0s;
    top: 10px; }

.copy-message.active {
  right: 0;
  transition: all 0.4s ease 0s;
  opacity: 1; }

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0; }

.thumbnail-card-container .ibox-content {
  padding: 0;
  border: 5px solid #e7eaec;
  height: 204px;
  overflow: hidden;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  position: relative; }

.template-img {
  height: 100%;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative; }

.preview-template {
  position: absolute;
  z-index: 2;
  background: #1ab394;
  padding: 10px;
  right: 0;
  bottom: 0;
  color: #fff; }

.thumbnail-card-container .ibox-content .choose {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -204px;
  background: #2f4050;
  opacity: .6;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  height: 204px;
  -webkit-transition: all .5s ease 0s;
  transition: all .5s ease 0s; }

.choose-hand {
  width: 50px;
  height: 50px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNv…4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  background-size: cover; }

/*
 * Fix for grapesjs overlap in panels
 */
.gjs-pn-views-container {
  width: 15%; }

.gjs-rte-actionbar {
  font-size: 1.25rem; }
