.display-flex {
  display: flex;
}

.page-templates-wrapper .page-template-card {
  position: relative;
  cursor: pointer;
  min-height: 100%;
}

.page-templates-wrapper .page-template-filters-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 46px;
}

.page-templates-wrapper .page-template-filters-dropdown {
  width: 200px;
}

.page-template-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.page-template-card-container {
    display: flex;
    margin-right: 80px;
}

.page-templates-wrapper .page-template-filter-label {
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
}

.page-template-type {
  height: 20px;
  background: #3e5066;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  position: relative;
  z-index: 12;
}

.page-templates-wrapper .ibox-content {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}

.page-templates-wrapper .ibox-content:hover {
  position: relative;
  /* outline: 2px solid #1ab394; 
  box-shadow: 0 3px 7px 0 #a8a8a8; */
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  transition: box-shadow 0.2s ease-in-out;
}

.page-template-card-image {
    width: 200px;
    height: 250px;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}

.template-status-indicator {
  width: 100%;
  height: 5px;
  background-color: red;
}

.active-template {
   background-color: #00e676;

}

.page-template-card .page-template-card-content {
    position: relative;
    padding: 0;
    width: 200px;
    height: 276px;
    overflow: hidden;
}

.page-templates-wrapper .page-template-card .page-template-card-toolbar {
    width: 100%;
    position: absolute;
    bottom: -40px;
    height: 40px;
    background: #1ab394;
    transition: all 0.3s ease-out;
}

.page-templates-wrapper .page-template-card-toolbar.selected {
    bottom: 0;
}

.page-template-card-toolbar .page-template-card-toolbar-icon {
  display: inline-block;
  height: 40px;
  width: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
}

.page-template-card-toolbar .page-template-card-toolbar-icon i {
  font-size: 26px;
  line-height: 40px
}

.page-template-close-icon {
  position: absolute;
  width: 100%;
  right: 10px;
  text-align: right;
  z-index: 10;
}

.page-templates-wrapper .rightPanel .page-template-close-icon  i {
  font-size: 20px;
}

.page-template-settings-section.rightPanel {
  z-index: 100;
}

.rightPanel, .add-new-template-section.rightPanel {
    bottom: 0;
}

.page-template-settings-section.rightPanel #template-status {
    margin-left: 10px;
}
/*
 * Tab Bar
 */

.nav.nav-tabs li a {
    line-height: 26px;
}
.required{
  color:  #ff0000;
  margin-bottom: 24px;
}

/*
 * Page templates right panel
 */

.add-new-template-section, .page-template-settings-section {
    top: 146px;
    height: 100vh;
    padding-bottom: 400px;
}

.page-templates-wrapper .page-template-settings-section .checkbox input {
    margin-left: 0;
}

.template-dropdown-menu {
    height: 350px;
    overflow-y: scroll;
}
.page-wrapper {
  overflow-y: auto;
}
#activepageTemplate {
  visibility: visible;
}
