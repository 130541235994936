@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
.gjs-editor{
    font-family: Montserrat, sans-serif;
}
.gjs-logo-cont {
    position: absolute;
    display: inline-block;
    top: 3px;
    padding: 0;
    margin: 0;
}
.gjs-pn-buttons span {
    font-size: 2.5em;
}
#gjs-clm-label, #gjs-clm-sel {
    font-size: 200%;
}
.gjs-label, .gjs-sm-title {
    font-size: 200%;
}
.gjs-input-holder input {
    font-size: 200%;
}
.gjs-input-holder select {
    font-size: 200%;
}
.gjs-sm-icon {
    font-size: 120%;
}
.gjs-sm-title, #gjs-clm-new {
    font-size: 200%;
}
option {
    color: black;
}
.gjs-am-assets-header{
    display: none;
}

.gjs-sm-title, .gjs-sm-field, .gjs-sm-label {
    font-size: 150%;
    font-family: Montserrat, sans-serif;
}
#gjs-sm-float,
.gjs-pn-views .fa-cog {
    display: none;
}
.gjs-pn-options .fa-download {
    display: none;
}
.gjs-logo {
    height: 25px;
}
.gjs-blocks-c div[title="Section"] {
    order: 1;
}
.gjs-blocks-c div[title="1 Column"] {
    order: 2;
}.gjs-blocks-c div[title="2 Columns"] {
    order: 3;
}
.gjs-blocks-c div[title="3 Columns"] {
    order: 4;
}
.gjs-blocks-c div[title="2 Columns 3/7"] {
    order: 5;
}
.gjs-blocks-c div[title="Text section"] {
    order: 6;
}
.gjs-blocks-c div[title="Header"] {
    order: 7;
}
.gjs-blocks-c div[title="Text"] {
    order: 8;
}
.gjs-blocks-c div[title="Quote"] {
    order: 9;
}
.gjs-blocks-c div[title="Link"] {
    order: 10;
}
.gjs-blocks-c div[title="Link Block"] {
    order: 11;
}
.gjs-blocks-c div[title="Image"] {
    order: 12;
}

.gjs-blocks-c div[title="Video"] {
    order: 13;
}
.gjs-blocks-c div[title="Map"] {
    order: 14;
}

.gjs-pn-options .fa-arrows-alt {
    display: none;
}
.gjs-pn-options .fa-code {
    display: none;
}
.cke_inner {
    background-color: blue;
}
.cke_chrome {
    border: none !important;
}
.cke_toolgroup {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
}
.gjs-block-category .gjs-blocks-c div[title="Card"]:after {
    background-image: url('http://res.cloudinary.com/ronaldaug/image/upload/v1518968468/card_urqoco.jpg');
    background-size: cover;
    width: 300px;
    height: 231px;
}

.gjs-block-category .gjs-blocks-c div[title="Blog"]:after {
    background-image: url('https://res.cloudinary.com/ronaldaug/image/upload/v1530116427/blog_qrhnhx.jpg');
    background-size: cover;
    width: 400px;
    height: 227px;
}

.gjs-block-category .gjs-blocks-c div[title="Starter"]:after {
    background-image: url('http://res.cloudinary.com/ronaldaug/image/upload/v1518968469/starter_shyx0y.png');
    background-size: cover;
    width: 400px;
    height: 196px;
}

.gjs-block-category .gjs-blocks-c div[title="Navbar 1"]:after {
    background-image: url('http://res.cloudinary.com/ronaldaug/image/upload/v1518968466/nav_he3fot.jpg');
    background-size: cover;
    width: 600px;
    height: 38px;
}

.gjs-block-category .gjs-blocks-c div[title="Navbar 2"]:after {
    background-image: url('http://res.cloudinary.com/ronaldaug/image/upload/v1518968469/nav2_iovg0o.jpg');
    background-size: cover;
    width: 600px;
    height: 129px;
}

.gjs-block-category .gjs-blocks-c div[title="Footer"]:after {
    background-image: url('http://res.cloudinary.com/ronaldaug/image/upload/v1518968467/footer_o8vuik.jpg');
    background-size: cover;
    width: 400px;
    height: 142px;
}

.gjs-block-category .gjs-blocks-c div[title="Badges"]:after {
    background-image: url('http://res.cloudinary.com/ronaldaug/image/upload/v1518968467/badges_hs1ta5.jpg');
    background-size: cover;
    width: 400px;
    height: 154px;
}

.gjs-block-category .gjs-blocks-c div[title="Members"]:after {
    background-image: url('https://res.cloudinary.com/ronaldaug/image/upload/v1530275580/members_v2x2tl.jpg');
    background-size: cover;
    width: 400px;
    height: 224px;
}

.gjs-block-category .gjs-blocks-c div[title="Slider"]:after {
    background-image: url('http://res.cloudinary.com/ronaldaug/image/upload/v1518968468/slider_l07ucf.jpg');
    background-size: cover;
    width: 400px;
    height: 154px;
}


.gjs-block-category .gjs-blocks-c div[title="Radio"]:after,
.gjs-block-category .gjs-blocks-c div[title="Checkbox"]:after,
.gjs-block-category .gjs-blocks-c div[title="Select"]:after,
.gjs-block-category .gjs-blocks-c div[title="Input"]:after,
.gjs-block-category .gjs-blocks-c div[title="Textarea"]:after {
    line-height: 2;
    position: fixed;
    top: 0;
    right: 220px;
    opacity: 0;
    z-index: 2;
    visibility: hidden;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    width: 300px;
    height: 44px;
    font-size: 13px;
    padding: 10px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    box-shadow: 1px 2px 10px rgba(116, 116, 116, 0.30);
    -moz-box-shadow: 1px 2px 10px rgba(116, 116, 116, 0.30);
    -webkit-box-shadow: 1px 2px 10px rgba(116, 116, 116, 0.30);
}

.gjs-block-category .gjs-blocks-c div[title="Radio"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Checkbox"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Select"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Textarea"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Input"]:hover:after {
    top: 30%;
    opacity: 1;
    display: block;
    visibility: visible;
}

.gjs-block.fa {
    font-size: 5em;
}
.gjs-block-category .gjs-blocks-c div[title="Parallax"]:after {
    background-image: url('http://res.cloudinary.com/ronaldaug/image/upload/v1518968470/parallax_hz2hot.jpg');
    background-size: cover;
    width: 400px;
    height: 197px;
}

.gjs-block-category .gjs-blocks-c div[title="Navbar 2"]:after,
.gjs-block-category .gjs-blocks-c div[title="Starter"]:after,
.gjs-block-category .gjs-blocks-c div[title="Badges"]:after,
.gjs-block-category .gjs-blocks-c div[title="Slider"]:after,
.gjs-block-category .gjs-blocks-c div[title="Footer"]:after,
.gjs-block-category .gjs-blocks-c div[title="Members"]:after,
.gjs-block-category .gjs-blocks-c div[title="Blog"]:after,
.gjs-block-category .gjs-blocks-c div[title="Navbar 1"]:after,
.gjs-block-category .gjs-blocks-c div[title="Parallax"]:after,
.gjs-block-category .gjs-blocks-c div[title="Card"]:after {
    content: '';
    line-height: 2;
    position: fixed;
    color: #fff;
    top: 0;
    right: 220px;
    opacity: 0;
    z-index: 2;
    visibility: hidden;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
}


.gjs-block-category .gjs-blocks-c div[title="Navbar 2"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Starter"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Badges"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Slider"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Blog"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Footer"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Members"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Navbar 1"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Parallax"]:hover:after,
.gjs-block-category .gjs-blocks-c div[title="Card"]:hover:after {
    top: 10%;
    opacity: 1;
    display: block;
    visibility: visible;
}

.right {
    float: right;
}

.gjs-block:hover {
    box-shadow: 0 3px 4px 0 rgba(204, 210, 251, 0.42);
}

.gjs-cm-editor #gjs-cm-title {
    background-color: rgba(164, 190, 241, 0.2);
}

.cm-s-hopscotch span.cm-tag {
    color: #9a648b;
}

.cm-s-hopscotch span.cm-atom {
    color: #836fb6;
}

.cm-s-hopscotch span.cm-number {
    color: #a06d91;
}

.cm-s-hopscotch span.cm-string {
    color: #816eb5;
}

.cm-s-hopscotch span.cm-property,
.cm-s-hopscotch span.cm-attribute {
    color: #5eb6cc;
}

.gjs-clm-tags #gjs-clm-close,
.gjs-clm-tags #gjs-clm-checkbox{
    color:#444;
}
.gjs-field-arrow-u{
    border-bottom:4px solid #444;
}
.gjs-field-arrow-d{
    border-top:4px solid #444;
}


.gjs-field .gjs-d-s-arrow {
    border-top: 4px solid rgb(68, 68, 68);
}
.gjs-color-warn,
.gjs-sm-sector .gjs-sm-field input,
.gjs-clm-tags .gjs-sm-field input,
.gjs-sm-sector .gjs-clm-field input,
.gjs-clm-tags .gjs-clm-field input,
.gjs-sm-sector .gjs-sm-field select,
.gjs-clm-tags .gjs-sm-field select,
.gjs-sm-sector .gjs-clm-field select,
.gjs-clm-tags .gjs-clm-field select {
    color: #fff;
}

.gjs-btn-html,
.gjs-btn-css,
.gjs-btn-export{
    float: right;
}

.gjs-btn-import,.gjs-btn-zip,.gjs-btn-export,.gjs-btn-load,.gjs-btn-css,.gjs-btn-html{
    color: #587899;
    font-size: 13px;
    margin:10px 0 10px;
}
button.exportgram {
    margin-left: 10px;
    padding: 4px 10px;
    background: #444;
    color: #fff;
    border-radius: 20px;
}

.html-wrapper,.css-wrapper{
    width:49%;
    float:left;
}
.html-wrapper h4,.css-wrapper h4{
    margin:4px 0 4px;
}
.gjs-toolbar .gjs-toolbar-item {
    font-size: 1.7rem;
}
.clear-head{
    width:100%;
    clear:both;
}
.gjs-clm-tag {
    font-size: 1.5em;
}
.css-wrapper{
    margin-left:2%;
}
.gjs-block-label,.gjs-block-label,.gjs-sm-header,#gjs-clm-new{
    font-size: 1.1rem;
    font-family: Montserrat, sans-serif;
}
.gjs-layer-name,.gjs-title,.gjs-layer{
    font-size: 1.2rem;
}
#gjs-input-holder, #gjs-sm-input-holder select,.input-unit, .gjs-radio-item {
    font-size: 200%;
}
.builder-loads{
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(116, 116, 116, 0.30);
}
.icon-settings{
    margin-right: 7px;
    margin-left: -3px;
}

.check-icon{
    float: left;
}
.success-message{
    line-height: 25px;
    margin: 0;
}
.save-message{
    position: absolute;
    top: 20px;
    right: -100%;
    min-width: 300px;
    background: rgba(75,181,67,0.8);
    color: #fff;
    z-index: 10;
    padding: 15px;
    border-radius: 4px;
    transition: all 0.4s ease 0s;
}
.save-message.active{
    right: 10px;
}
.error-message{
    position: absolute;
    top: 20px;
    right: 10px;
    min-width: 300px;
    background: red;
    color: #fff;
    z-index: 10;
    padding: 15px;
    border-radius: 4px;

}
.builder-loading-layer{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.9);
    z-index: 5;
}
.gjs-field-arrows{
    cursor: default;
    width: 12px;
    height: 25px;
    background: #ccc;
}
.gjs-field-arrow-u, .gjs-field-arrow-d{
    height: 4px;
    width: 12px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}
.gjs-field-units{
    right: 15px;
}
.open-custom-popup{
    background-image: url(../../img/popupimage.png);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    position: relative;
    top: 1px;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 6px !important;
    background-color: #8e7b7b85 !important;
    border: 1px solid #8e7b7b !important;
    outline: none;
    opacity: 1;
    border-radius: 4px;
    -webkit-transition: .2s;
    -webkit-transition: opacity .2s;
    -o-transition: opacity .2s;
    transition: opacity .2s;
  }
  
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 20px;
    background: #fff;
    cursor: pointer;
    border-radius: 10px
  }
  
  .slider::-moz-range-thumb {
    width: 10px;
    height: 20px;
    background: #fff;
    cursor: pointer;
    border-radius: 10px;
  }
  .gjs-field-buttonLogin{
      background-color: inherit;
  }
  .input-value-span{
    float: right;
    background: #fff;
    width: 30px;
    height: 20px;
    color: #333;
    font-size: 14px;
    text-align: center;
  }
  .gjs-field-integer{
    -ms-flex: 1 1 85px !important;
     flex: 1 1 85px !important;
  }
.modal-save-btn{
    margin:0px 10px;
}

#exit-modal .modal-cancel-btn{
    margin:0px 5px;
}
#exit-modal .exit-modal-popup{
    width:300px;
}
#exit-modal .modal-dialog{
    max-width:100%;
    width:400px !important;
    top: 30%;
}
#exit-modal .save-cancel-btn{
    margin-top: 30px;
}
#exit-modal .confirmation{
    font-size: 16px;
}
#exit-modal .modal-title{
    text-align: center;
}
.image-block {
    width: auto;
    float: left;
}

#imageContainer .heading .images,
#imageContainer .heading h1 {
    width: 33.333%;
    float: left;
    max-width: 100%;
}
#icon-modal .modal-dialog{
    width:500px !important;
}
#imageContainer{
    width: 100%;
    overflow: auto;
}
.searchIcons{
    position: absolute;
    right: 40px;
    top: 26px;
    font-size: 20px !important;
}
.gjs-am-assets-cont{
    height: auto !important;
}
.fa.fa-thumb-tack{
    transform: rotate(40deg);
    font-size: 22px !important;
    margin-top: -4px;
}
#gjs-sm-font-family,#gjs-sm-border-style{
    width: 100% !important;
}