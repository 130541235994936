.walkthrough-peekaboo {
    position: fixed;
    z-index: 3;

    #walkthrough-animation-container {
        width: 600px;
        height: 150px;
        background: transparent;
        border:1px solid white;
        opacity: 0;
    }
}

.walkthrough {

    &-modal {
        .walkthrough-modal-body {
            background: #f1f1f1;
        }
    }


    &-body {
        margin-top: 60px;
    }

    &-slider, &-no-slide {
        margin-bottom: 10px;
    }


    &-tile {
        display: inline-block;
        text-align: center;
        width: 265px;
        height: 200px;
        margin-right: 10px;
    
        h3 {
            img {
                width: 25px;
                height: 25px;
                display: inline-block;
                margin-right: 10px;
            }
            font-weight: bold;
            font-size: 18px;
            min-height: 50px;
        }
    
        .ibox-content {
            border: none;
            padding: 30px;
        
            .description {
                margin-bottom: 20px;
                font-size: 12px;
                color: #777;
                min-height: 50px;
                overflow: hidden;
            }

            &:hover {
                border: none;
            }
        }
    }

    &-footer {
        display: flex;
        justify-content: space-between;
    }
}

.build-funnel {
    &-info-section {
        padding: 20px;
        background: white;
    }

    .error-text {
        font-size: 12px;
        color: red;
        margin-top: 5px;
        display: inline-block;
    }

    &-input-text {
        min-height: 120px;
    }
}
