@import "../../variables.scss";
@import "./visualizer-header/visualizer-header";
@import "./visualizer-left-panel/visualizer-left-panel";
@import "./visualizer-right-panel/visualizer-right-panel";
@import "./visualizer-frame/visualizer-frame";

.visualizer {
    position: relative;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
}
.visualizer-canvas{
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    height: calc(100vh - 155px);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}