.visualizer-right-panel{
    position: absolute;
    top:116px;
    bottom: 0px;
    right: -100%;
    width: 400px;
    box-shadow: -1px 4px 20px #4c4c4c38;
    background: $white;
    transition: all 0.4s ease 0s;
    overflow: auto;
    overflow-x: hidden;
    padding: 20px 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 70px;
    display: inline-block;
    &.active{
        right: 0;
    }
    .ibox-content{
        padding: 0;
        border: 5px solid #e7eaec;
        height: 204px;
        overflow: hidden;
        transition: all 0.3s ease 0s;
        position: relative;
        .choose{
            position: absolute;
            right: 0;
            left: 0;
            bottom: -204px;
            background: #2f4050;
            opacity: 0.6;
            z-index: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            color: $white;
            font-size: 14px;
            font-weight: 700;
            height: 204px;
            transition: all 0.5s ease 0s;
        }
    } .ibox-content:hover .choose{
        bottom: 0;
    }
    .custom-checkBox{ 
        position: fixed!important;
        bottom: 50px;
        box-shadow: 0px -4px 20px #4c4c4c38;
        width: 390px;
        padding: 10px;
        margin: 0 0 0 -15px!important;
        background: #fff;
        z-index: 4;
    }
    .custom-checkBox input{ 
        display: none
    }
    .custom-checkBox label{
        height: 40px;
        background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'><g><g><path d='M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.331,26.629,74.98,74.98C26.628,123.332,0,187.62,0,256 s26.628,132.667,74.98,181.019C123.332,485.371,187.619,512,256,512c68.38,0,132.667-26.629,181.019-74.981 C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M256,482C131.383,482,30,380.617,30,256S131.383,30,256,30 s226,101.383,226,226S380.617,482,256,482z' fill='%23cccccc'/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>");
        background-size: contain;
        background-repeat: no-repeat;
        padding-left: 50px!important;
        line-height: 40px;
        font-size: 1.3em;
    }
    .custom-checkBox input:checked+label{
        background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'><g><g><path fill='%231ab394' d='M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.332,26.629,74.98,74.98C26.629,123.332,0,187.62,0,256 s26.629,132.667,74.98,181.019C123.332,485.371,187.62,512,256,512s132.667-26.629,181.019-74.98 C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M378.306,195.073L235.241,338.139 c-2.929,2.929-6.768,4.393-10.606,4.393c-3.839,0-7.678-1.464-10.607-4.393l-80.334-80.333c-5.858-5.857-5.858-15.354,0-21.213 c5.857-5.858,15.355-5.858,21.213,0l69.728,69.727l132.458-132.46c5.857-5.858,15.355-5.858,21.213,0 C384.164,179.718,384.164,189.215,378.306,195.073z'/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>") no-repeat;
    }
    .checkbox label {
        font-weight: bold !important;
    }
    .slider-section {
        float: left;
        width: 100%;
        height: 60px;
        position: relative;
    }
    .slider-section .pageValue2{
        position: absolute;
        top: 14px;
        background: #fff;
        left: 0px;
        padding: 0 10px 0 0;
        right: auto;
    }
}