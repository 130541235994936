.designers-profile{
    background-position: top center;
    width: 100px;
    height: 100px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
}
.contact-box{
    height: 200px;
    padding: 15px 0;
}
.contact-box h2{
    margin-top: 0;
}
.contact-box h2,.profession,.info-details{
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
}
.info-details{
    margin: 5px 0;
}
.info-details i{
    padding-right: 10px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.phone:before {
    content: "\F095";
}
.skype:before {
    content: "\F17E";
}
.email:before {
    content: "\F0E0";
}