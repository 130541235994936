@import "../variables.scss";

.modal {
  
  .modal-header {
    background: $secondary;
  }
  
  .modal-content {
    .close-button {
      position: absolute;
      right: 20px;
      top: 10px;
      display: inline-block;
      font-family: FontAwesome;
      text-rendering: auto;
      font-size: 16px;
      font-weight: normal;
      background: $light-gray;
      padding: 3px 6px;
      color: $primary;
      cursor: pointer;
      &:before {
        content: "\f00d";
      }
    }

    .modal-title {
      font-size: 16px;
      font-weight: 600;
      color: white;
    }
  }

  &.modal-top-spacer {
    top: 30px;
  }

}

.modal-right-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .share-section {
    width: auto;
  }
}

.existing-funnels-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.existing-funnels-modal .modal-dialog { 
  width: 90%;
  height: 90%;
}

.existing-funnels-modal  .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  box-shadow: none;
}
