.visualizer-left-panel{
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    .pageList{
        position: relative;
        z-index: 1;
        left: 0;
        transition: all 0.4s ease 0s;
        width: 120px;
        display: flex;
        max-height: calc(100vh - 146px);
        overflow: auto;
        .joint-port{
            display: none;
        }
        .label{
            font-weight: 400;
            font-size: 11px;
            fill: $secondary;
        }
        .pageList .iconbar,.pageList text.icons {
            display: none;
        }
    }
}
#flyPaper {
    .joint-port{
        display: none;
    }
}
.borderBox{
    stroke-width: 1px;
    fill-opacity: 0;
}