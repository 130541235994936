
.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}
.thumbnail-card-container .ibox-content {
    padding: 0;
    border: 5px solid #e7eaec;
    height: 204px;
    overflow: hidden;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    position: relative;
}
.template-img {
    height: 100%;
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}
.preview-template {
    position: absolute;
    z-index: 2;
    background: #1ab394;
    padding: 10px;
    right: 0;
    bottom: 0;
    color: #fff;
}
.thumbnail-card-container .ibox-content .choose {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -204px;
    background: #2f4050;
    opacity: .6;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 204px;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
}
.choose-hand {
    width: 50px;
    height: 50px;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNv…4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
    background-size: cover;
}
