@import "../variables.scss";

/*
 * Vendor Libraries
 */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "../components/walkthrough-modal";

#dash-leftSide {
    z-index: 2;
}