.funnelThumbnail {
    width: 300px;
    height: 300px;
    margin: 8px;
    float: left;
    border: 5px solid #e7eaec;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    position: relative;
    background: #fff;
    position: relative;
  }


  .funnelThumbnail:hover{
    border: 3px solid #1ab394;
    -webkit-box-shadow: 0 3px 7px 0 #a8a8a8;
    -moz-box-shadow: 0 3px 7px 0 #a8a8a8;
    box-shadow: 0 3px 7px 0 #a8a8a8;
}

.funnelThumbnail .choose{
    position: absolute;
    right: 0;
    left: 0;
    bottom: -310px;
    background: #2f4050;
    opacity: 0.6;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 235px;
    padding-top: 50px;
    transition: all 0.5s ease 0s;
}

.funnelThumbnail.import-funnel-card:hover .choose {
    bottom: -5px;
}


.centerItem{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.addIcon{
    height: 50px;
    width: 50px;
}

.funnelThumbnail .delete{
    position: absolute;
    right: 0;
    left: 0;
    bottom: -150px;
    background: #f30909;
    opacity: 1;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 50px;
    transition: all 0.5s ease 0s;
}

.funnelThumbnail :hover .choose {
    bottom: 0;
}
.funnelThumbnail :hover .delete {
    top: 0;
}

.thumbnail-template-img {
    height: 230px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
    
.thumbnail-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    bottom:0;
    padding-top: 5px; 
    padding-left: 10px; 
}
.thumbnail-content .abbrtitle {
    border-bottom: none ;    
    text-decoration: none ;
    cursor: pointer;
}
.centerList{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.parent-content{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    height: 64px;
    align-items: center;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.font-16{
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
}
.thumbnail-name{
    flex: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
.thumbnail-price{
    flex: 4;
    text-align: center;
}
.width-100{
    width: 100%;
}