.visualizer-frame{
    display: flex;
    width: calc(100% - 120px);
    padding: 20px;
    overflow: auto;
    .canvas-container{
        position: relative;
        display: flex;
        flex: 1;
        .joint-cell .iconbar,.joint-cell .icons,.joint-cell .linkicon{
            display: none;
        }
        .joint-cell.active + .joint-cell[data-type="standard.Rectangle"] .iconbar, .joint-cell.active + .joint-cell .icons,.joint-cell.active .port-body,.joint-cell.active .iconbar,.joint-cell.active .icons,.joint-cell.active .linkicon{
            display: block;
        }
        .joint-cell.active + .joint-cell[data-type="Page Model"] .icons{
            display: none;
        }
        .joint-cell .port-body{
            pointer-events: none;
        }
        .joint-cell.active .port-body{
            pointer-events: fill;
        }
        .joint-cell.port-enable .port-body{
            pointer-events: fill;
        }
        .joint-cell.active .joint-highlight-stroke.joint-theme-default {
            display: block;
        }
        .iconbar{
            fill: #1ab394!important;
            width: 170px;
            height: 50px;
        }
        text.icons{
            font-family: FontAwesome;
            font-size: 24px;
            fill: #ffffff;
            cursor: pointer;
        }
        text.linkicon{
            font-family: FontAwesome;
            font-size: 20px;
            fill: #1ab394;
        }
        .link-tools .tool-options{
            display: block;
        }
    }
}