.btn-import-default-templates {
    position: fixed;
    right: 40px;
    bottom: 60px;
}

.import-page-loader {    
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.9);
    z-index: 5;
    width: 100vw;
    height: 100vh;
    
    i {
        font-size: 32px;
        color: #1ab394;
        margin-top: -100px;
    }
}