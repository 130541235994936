.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.close-icon {
	border:1px solid transparent;
	background-color: transparent;
	display: inline-block;
	vertical-align: middle;
  outline: 0;
  cursor: pointer;
}

.field:hover .close-icon {
  opacity: 1;
}

/* Common CSS Contents */
.close-icon {
  opacity: 0;
	width: 30px;
	height: 30px;
	position: absolute;
	background-color: #ff0000;
	z-index:1;
	right: 25px;
	top: 0px;
	bottom: 0;
	margin: auto;
	padding: 2px;
	/* border-radius: 50%; */
	text-align: center;
	color: white;
	font-weight: normal;
	font-size: 12px;
	box-shadow: 0 0 2px #E50F0F;
	cursor: pointer;
	line-height: 27px;
}

.search-box,.close-icon,.search-wrapper {
	padding-bottom: 5px;
}

.disable{
	pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  background: #CCC;
}
