.errorPage{
    display: flex;
    flex: 1;
    height: calc(100vh - 100px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.errorNo{
    font-size: 10em;
    line-height: 1em;
}
.errorContainer{
    flex-direction: column;
    display: flex;
    align-items: center;
}
.errorContent{
    font-size: 2em;
    line-height: 1em;
    font-weight: bold;
}
.errorImg{

}