.visualizer-header{
    background-color: $primary;
    width: 100%;
    padding: 10px 15px;
    text-align: right;
    display: inline-block;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    z-index: 11;
    position: relative;
    .header-content{
        display: flex;
        // width: 100%;
        // justify-content: space-between;
        justify-content: flex-end;
        flex: 1;
    }
    .funnel-title{
        float: left;
        line-height: 34px;
        color: $white;
        margin: 0;
        span{
            white-space: nowrap;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            float: left;
        }
    }
    .funnelSettings{
        background: #ffffff;
        height: 0;
        z-index: 10;
        transition: all 0.4s ease 0s;
        text-align:left;
        overflow: hidden;
        opacity: 0;
        background: #ffffff;
        border: 1px solid #e7eaec;
        padding: 15px;
        margin-top: -100%;
        margin-bottom: 20px;
        position: absolute;
        top: 40px;
        right: 10px;
        left: 10px;
        z-index: 10;
        transition: all 0.4s ease 2s;
        &.active{
            opacity: 1;
            min-height: 355px;
            margin-top: 15px;
            height: auto;
            border-bottom: 5px solid $secondary;
            padding: 15px;
            transition: all 0.4s ease 0s;    
        }
    }
    /*************** Zoom Section Styling **********************/
    .zoomBox{
        width: 300px;
        .input-group-addon{
            background: $secondary;
            border:none;
            color: $white;
            i{
                font-size: 20px;
            }
        }
        .zoom-level{
            text-align: center;
        }
    }

    /**********************************************************/

    /*************** Live Site Button Styling **********************/

    .disable-pointer {
        pointer-events: none;
      }

    a.text-white:hover,a.text-white:focus{
        color: #ffffff;
    }

    /*************** Live Site Button Styling **********************/
    .dottedLine:hover,.text-element:hover,.dottedLine.active,.text-element.active{
        background: #1ab394
    }
    
    .dottedLine, .text-element {
        width: 60px;
        position: relative;
        height: 54px;
        text-align: center;
        line-height: 54px;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
        overflow: hidden;
        margin-top: -10px;
        margin-bottom: -10px;
        cursor: pointer;
    }
    .dottedLine::after{
        content:"";
        width: 30px;
        height: 30px;
        background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgOTcyIDk3MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgOTcyIDk3MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGQ9Ik05MCw1NzZoMC41YzQ5LjcsMCw5MC00MC4zLDkwLTkwcy00MC4zLTkwLTkwLTkwSDkwYy00OS43LDAtOTAsNDAuMy05MCw5MFM0MC4zLDU3Niw5MCw1NzZ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8cGF0aCBkPSJNNjE3LjUsNTc2aDEuMWM0OS43LDAsOTAtNDAuMyw5MC05MHMtNDAuMy05MC05MC05MGgtMS4xYy00OS43LDAtOTAsNDAuMy05MCw5MFM1NjcuOCw1NzYsNjE3LjUsNTc2eiIgZmlsbD0iI0ZGRkZGRiIvPgoJPHBhdGggZD0iTTM1NC41LDU3NmM0OS43LDAsOTAtNDAuMyw5MC05MHMtNDAuMy05MC05MC05MGgtMS4xYy00OS43LDAtOTAsNDAuMy05MCw5MHM0MC4zLDkwLDkwLDkwSDM1NC41eiIgZmlsbD0iI0ZGRkZGRiIvPgoJPHBhdGggZD0iTTg4MS41LDU3NmgwLjVjNDkuNywwLDkwLTQwLjMsOTAtOTBzLTQwLjMtOTAtOTAtOTBoLTAuNWMtNDkuNywwLTkwLDQwLjMtOTAsOTBTODMxLjgsNTc2LDg4MS41LDU3NnoiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 50%;
        top: 11px;
        margin-left: -15px;
        transform: rotate(-45deg);
    }
}
.joint-highlight-stroke.joint-theme-default {
    display: none;
}
.joint-cell.active .joint-highlight-stroke.joint-theme-default {
    display: block;
}
.marker-vertex-group:first-child .marker-vertex-remove-area,.marker-vertex-group:first-child .marker-vertex-remove,.marker-vertex-group:last-child .marker-vertex-remove-area,.marker-vertex-group:last-child .marker-vertex-remove{
    display: none;
}
.connection[stroke-dasharray="2,5"] + .marker-source + .marker-target +.connection-wrap + .marker-vertices + .marker-arrowheads + .link-tools .link-tool .tool-options,.connection[stroke-dasharray="2,5"] + .marker-source + .marker-target +.connection-wrap + .labels + .marker-vertices + .marker-arrowheads + .link-tools .link-tool .tool-options{
    display: none;
}
.joint-cell .iconbar,.joint-cell .icons,.joint-cell .linkicon{
    display: none;
}
.joint-cell.active + .joint-cell[data-type="standard.Rectangle"] .iconbar, .joint-cell.active + .joint-cell .icons,.joint-cell.active .port-body,.joint-cell.active .iconbar,.joint-cell.active .icons,.joint-cell.active .linkicon{
    display: block;
}
.joint-cell.active + .joint-cell[data-type="devs.MyImageModel"] .icons, .joint-cell.active + .joint-cell[data-type="Page Model"] .icons{
    display: none;
}
.joint-cell .port-body{
    pointer-events: none;
}
.joint-cell.active .port-body{
    pointer-events: fill;
}
.joint-cell.port-enable .port-body{
    pointer-events: fill;
}
.port-body{
    opacity: 0;
}

/*
 * Move this html-element
 */

.visualizer-graph {
    &-text-element {
        position: absolute;
        z-index: 2;
        /* Make sure events are propagated to the JointJS element so, e.g. dragging works.*/
        pointer-events: none;
        background: rgba($primary-color, 0.2);
        padding: 10px 20px;
        user-select: none; /* Standard syntax */
        .icons {
            font-family: FontAwesome;
            font-size: 24px;
            fill: #ffffff;
            cursor: pointer;
        
            &.trash {
                color: #1ab394;
            }
        }

        input {
            display: none;
        }

        .icon-container {
            opacity: 0;
        }
        
        &.focussed {
            input {
                display: block;
            }
            label {
                display: none;
            }
            .icon-container {
                opacity: 1;
                pointer-events: auto;
            }
        }

        .icon-container {
            position: absolute;
            top: -30px;
            left: 0;
            pointer-events: auto;
            z-index: 100;
        }
    }
    &-input-container {
        position: relative;
        height: 100%;
        input, label {
            border: none;
            outline: none;
            color: $primary-color;
            font-size: 18px;
            font-weight: bold;
            pointer-events: auto;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            user-select: none; /* Standard syntax */
        }
    }
}
