  .copy-message{
    position: absolute;
    background: #1ab394;
    color: #ffffff;
    padding: 5px 15px;
    top: 8px;
    z-index: 3;
    border-radius: 5px;
    transition: all 0.4s ease 0s;
    right: -100%;
    opacity: 0;
    &:after{
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #1ab394;
      position: absolute;
      bottom: -8px;
      left: 10px;
      transition: all 0.4s ease 0s;
    }
  }
  .share-section {
    text-align: right;
    padding: 15px;
    width: 100%;    
  }
  .share-popup {
    float: left;
    width: calc(100% - 30px);
    text-align: left;
    overflow: hidden;
    transition: all 0.4s ease 0s;
    position: absolute;
    z-index: 3;
    background: #ffffff;
    margin-top: 4px;
    padding: 15px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.2);
    top:70px;
    right: 15px;
    &.active{
      height: auto;
      transition: all 0.4s ease 0s;
      top: 10px
    }
  }
  .copy-message.active{
    right: 0;
    transition: all 0.4s ease 0s;
    opacity: 1;
  }